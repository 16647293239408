
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';

import Select from '@/components/form/Select.vue';
import TaskStatus from '@/models/task-status.model';
import Store from '@/store';
import TaskStatusService from '@/services/task-status.service';

type Option = {
	key: number;
	value: string;
};

export default defineComponent({
	props: ['modelValue', 'borderless'],

	components: {
		Select,
	},

	setup(props, ctx) {
		const selected: Ref = ref(null);
		const statuses = Store.get('statuses');
		const options: Ref<Option[]> = ref([]);

		onMounted(async () => {
			if (!statuses.value) {
				await TaskStatusService.get().then((response) => {
					if (response) {
						const newStatuses = [...response];

						const doneElement = newStatuses.splice(newStatuses.length - 1, 1);

						newStatuses.splice(newStatuses.length - 1, 1);

						newStatuses.splice(3, 0, doneElement[0]);

						Store.set('statuses', newStatuses);
					}
				});
			}

			statuses.value.forEach((status: TaskStatus) => {
				if (!status.color) {
					status.color = 'default color';
				}

				const div_string = `<div class="inline-block py-1 px-2 text-xs font-bold rounded" style="color: ${status.color}; background-color: ${status.color}38;">${status.title}</div>`;

				options.value.push({ key: status.id, value: div_string });
			});

			load();
		});

		watch(
			() => props.modelValue,
			() => {
				load();
			}
		);

		function load() {
			if (isNaN(props.modelValue)) {
				return;
			}

			selected.value = props.modelValue;
		}

		watch(selected, (value: number) => {
			const status = statuses.value.find((status: TaskStatus) => status.id == value);

			ctx.emit('update:modelValue', status);
		});

		return {
			// data
			selected,
			statuses,
			options,
		};
	},
});
